@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  color: #1677ff;
}

h1 {
  @apply text-3xl;
}
h2 {
  @apply text-2xl;
}
h3 {
  @apply text-xl;
}
h4 {
  @apply text-lg;
}
h5 {
  @apply text-base;
}
h6 {
  @apply text-sm;
}
h1,
h2 {
  @apply font-bold;
}
h3,
h4,
h5,
h6 {
  @apply font-medium;
}

/* Hide the Resize Observer notification */
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
